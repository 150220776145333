<template>
  <v-row justify="center">
    <v-container v-if="loading">
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </v-container>
    <v-container v-else class="pa-0 ma-0">
      <v-row v-if="completeLeadSurvey" class="pa-0 ma-0">
        <v-container class="ma-0 pa-0 px-2">
          <v-row class="pa-0 ma-0 mt-4">
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <div>
                <h4 class="black--text">
                  Objetivo de adquirir esta propiedad
                </h4>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" justify="space-between" align="center">
                <v-radio-group
                  v-model="objectiveSelected.answered"
                  row
                  :disabled="!watchAndEdit"
                >
                  <v-radio
                    class="caption ma-0 pa-0 px-2"
                    v-for="(item, index) in objectiveSelected.answers"
                    :key="index"
                    :label="item"
                    :value="item"
                    @click="sendAnswer(objectiveSelected.question, item)"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <div>
                <h4>
                  Tipología de interés
                </h4>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="pa-0 ma-0 px-2">
              <v-row class="pa-0 ma-0" align="center">
                <v-col
                  v-for="(item, index) in typeSelected.answers"
                  :key="index"
                  class="ma-p pa-0"
                  cols="12"
                  sm="6"
                >
                  <v-row justify="start" align="center">
                    <v-checkbox
                      :disabled="!watchAndEdit"
                      :input-value="typeSelected.multiAnswered.includes(item)"
                      value
                      :label="item"
                      @change="sendAnswer(typeSelected.question, item)"
                    ></v-checkbox>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" class="pa-0 ma-0 px-2">
              <div>
                <h4>Forma de pago</h4>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="pa-0 ma-0 pt-1">
              <v-row justify="space-between" align="center" class="pa-0 ma-0">
                <v-col
                  v-for="(item, index) in paySelected.answers"
                  :key="index"
                  class="pa-0 ma-0"
                  cols="10"
                  sm="6"
                >
                  <v-checkbox
                    :disabled="!watchAndEdit"
                    class="ma-0 pa-0"
                    :input-value="paySelected.multiAnswered.includes(item)"
                    :label="item"
                    value
                    @change="sendAnswer(paySelected.question, item)"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <div>
                <h4>¿Cuánto llevas buscando?</h4>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" justify="space-between" align="center">
                <v-radio-group
                  v-model="searchingTime.answered"
                  row
                  :disabled="!watchAndEdit"
                >
                  <v-radio
                    v-for="(item, index) in searchingTime.answers"
                    :key="index"
                    :label="item"
                    :value="item"
                    @click="sendAnswer(searchingTime.question, item)"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <div>
                <h4>¿Qué opciones ha visto hasta el momento?</h4>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" justify="space-between" align="center">
                <v-text-field
                  :disabled="!watchAndEdit"
                  :loading="innerLoading === 'saw'"
                  placeholder="Ingresa una respuesta"
                  outlined
                  v-model="optionsSaw.answered"
                  @blur="sendAnswer(optionsSaw.question, optionsSaw.answered)"
                >
                </v-text-field>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <div>
                <h4>¿Estás trabajando con alguien más?</h4>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" justify="space-between" align="center">
                <v-text-field
                  :disabled="!watchAndEdit"
                  :loading="innerLoading === 'working'"
                  placeholder="Ingresa una respuesta"
                  outlined
                  v-model="workingWith.answered"
                  @blur="sendAnswer(workingWith.question, workingWith.answered)"
                >
                </v-text-field>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <div>
                <h4>¿Cuál es tu plazo para tomar la decisión?</h4>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" justify="space-between" align="center">
                <v-radio-group v-model="deadlineTime.answered" row>
                  <v-radio
                    :disabled="!watchAndEdit"
                    v-for="(item, index) in deadlineTime.answers"
                    :key="index"
                    :label="item"
                    :value="item"
                    @click="sendAnswer(deadlineTime.question, item)"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </v-row>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      slider: 0,
      role: localStorage.getItem("user_role"),
      publicPath: process.env.BASE_URL,
      innerLoading: false,
      userRole: localStorage.getItem("user_role") || "",
      completeLeadSurvey: null,
      typeSelected: null,
      paySelected: null,
      objectiveSelected: null,
      searchingTime: null,
      optionsSaw: null,
      workingWith: null,
      deadlineTime: null,
      leadCurrency: null
    };
  },
  computed: {
    ...mapState({
      loading: state => state.leads.loading,
      lead: state => state.leads.actualItem
    }),
    watchAndEdit() {
      if (this.role == "admin") return true;
      if (this.role == "broker") return true;
      return false;
    },
    leadName: {
      get() {
        if (this.lead && this.lead.contact_lead_name) {
          return this.lead.contact_lead_name;
        }
        return "";
      },
      set(newValue) {
        this.$store.commit("leads/updateActualLead", {
          name: newValue,
          contact_lead_name: newValue
        });
      }
    },
    contactLeadEmail: {
      get() {
        if (this.lead && this.lead.contact_lead_email)
          return this.lead.contact_lead_email;
        return "";
      },
      set(newValue) {
        this.$store.commit("leads/updateActualLead", {
          contact_lead_email: newValue
        });
      }
    }
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        if (this.lead._id !== undefined) {
          this.getCompleteLeadSurvey(this.lead._id);
        } else {
          this.completeLeadSurvey = null;
        }
      } else {
        this.completeLeadSurvey = null;
      }
    }
  },
  mounted() {},
  created() {},
  methods: {
    ...mapActions({
      getTrafficDataStore: "getTrafficData",
      fetchUpdatelLead: "leads/fetchUpdatelLead",
      fetchCompleteLeadSurveySt: "leads/fetchCompleteLeadSurvey",
      nextQuestionSt: "leads/nextQuestion"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    getCompleteLeadSurvey(leadId) {
      this.fetchCompleteLeadSurveySt(leadId).then(res => {
        this.completeLeadSurvey = res.completeLeadSurvey;
        this.getAnwersQBuestionyId("5de80b4cba1606a3e17556c0");
        this.getAnwersQBuestionyId("5de80c1aba1606a3e175573a");
        this.getAnwersQBuestionyId("5de80b81ba1606a3e17556de");
        this.getAnwersQBuestionyId("5de80bc3ba1606a3e1755703");
        this.getAnwersQBuestionyId("5de80c59ba1606a3e175575b");
        this.getAnwersQBuestionyId("5de80bdcba1606a3e175571a");
        this.getAnwersQBuestionyId("5de80bf8ba1606a3e1755725");
      });
    },
    getAnwersQBuestionyId(id) {
      switch (id) {
        case "5de80b4cba1606a3e17556c0":
          this.objectiveSelected = {
            questionId: id,
            question: "Objetivo",
            answered: this.getAnswerById(id),
            multiAnswered: [],
            answers: ["Habitar", "Invertir", "Rentar"]
          };
          break;
        case "5de80c1aba1606a3e175573a":
          this.typeSelected = {
            questionId: id,
            question: "Tipologia",
            answered: this.getAnswerById(id),
            multiAnswered:
              this.getAnswerById(id) === ""
                ? []
                : this.getAnswerById(id).split(","),
            answers: [
              "Departamento",
              "Casa",
              "Consultorios",
              "Locales Comerciales",
              "Oficinas",
              "Bodegas",
              "Estudio",
              "1 Rec",
              "2 Rec",
              "3 Rec",
              "4 Rec",
              "+4 Rec",
              "PH",
              "Terrenos",
              "N/A"
            ]
          };
          break;
        case "5de80b81ba1606a3e17556de":
          this.paySelected = {
            questionId: id,
            question: "Forma",
            answered: this.getAnswerById(id),
            multiAnswered:
              this.getAnswerById(id) === ""
                ? []
                : this.getAnswerById(id).split(","),
            answers: [
              "Recursos propios",
              "Crédito Bancario",
              "Infonavit",
              "Financiamiento Directo"
            ]
          };
          break;
        case "5de80bc3ba1606a3e1755703":
          this.searchingTime = {
            questionId: id,
            question: "Tiempo buscando",
            answered: this.getAnswerById(id),
            multiAnswered: [],
            answers: [
              "No estaba buscando",
              "Este mes inició",
              "2 meses",
              "3 meses",
              "3 a 6 meses",
              "+ 6 meses"
            ]
          };
          break;
        case "5de80c59ba1606a3e175575b":
          this.optionsSaw = {
            questionId: id,
            question: "Opciones que has visto",
            answered: this.getAnswerById(id),
            multiAnswered: [],
            answers: []
          };
          break;
        case "5de80bdcba1606a3e175571a":
          this.workingWith = {
            questionId: id,
            question: "Trabajandon alguien",
            answered: this.getAnswerById(id),
            multiAnswered: [],
            answers: []
          };
          break;
        case "5de80bf8ba1606a3e1755725":
          this.deadlineTime = {
            questionId: id,
            question: "Decision",
            answered: this.getAnswerById(id),
            multiAnswered: [],
            answers: [
              "Menos de 30 días",
              "1 a 3 meses",
              "3 a 6 meses",
              "+ de 6 meses"
            ]
          };
          break;
        default:
          return {
            answered: "",
            multiAnswered: [],
            answers: [""]
          };
      }
    },
    getAnswerById(id) {
      if (this.completeLeadSurvey) {
        let index = this.completeLeadSurvey.findIndex(l => {
          return l.question_id === id;
        });
        let answer = this.completeLeadSurvey[index].answered;
        return answer ? answer : "";
      } else {
        return "";
      }
    },
    sendAnswer(question, answer) {
      switch (question) {
        case "Objetivo":
          this.objectiveSelected.answered = answer;
          this.sendAnswerNextQuestion(
            this.objectiveSelected.questionId,
            this.objectiveSelected.answered
          );
          break;
        case "Tipologia":
          if (this.typeSelected.multiAnswered.includes(answer)) {
            let tmpList = this.typeSelected.multiAnswered.filter(item => {
              return item !== answer;
            });
            this.typeSelected.multiAnswered = tmpList;
            this.typeSelected.answered = tmpList.join(",");
          } else {
            this.typeSelected.multiAnswered.push(answer);
            this.typeSelected.answered = this.typeSelected.multiAnswered.join();
          }
          this.sendAnswerNextQuestion(
            this.typeSelected.questionId,
            this.typeSelected.answered
          );
          break;
        case "Forma":
          if (this.paySelected.multiAnswered.includes(answer)) {
            let tmpList = this.paySelected.multiAnswered.filter(item => {
              return item !== answer;
            });
            this.paySelected.multiAnswered = tmpList;
            this.paySelected.answered = tmpList.join(",");
          } else {
            this.paySelected.multiAnswered.push(answer);
            this.paySelected.answered = this.paySelected.multiAnswered.join();
          }
          this.sendAnswerNextQuestion(
            this.paySelected.questionId,
            this.paySelected.answered
          );
          break;

        case "Tiempo buscando":
          this.searchingTime.answered = answer;
          this.sendAnswerNextQuestion(
            this.searchingTime.questionId,
            this.searchingTime.answered
          );
          this.sendUpdateOneField("contact", {
            searching_time: this.getDateBefore(answer)
          });
          break;
        case "Opciones que has visto":
          this.innerLoading = "saw";
          this.optionsSaw.answered = answer;
          this.sendAnswerNextQuestion(
            this.optionsSaw.questionId,
            this.optionsSaw.answered
          );
          break;
        case "Trabajandon alguien":
          this.innerLoading = "working";
          this.workingWith.answered = answer;
          this.sendAnswerNextQuestion(
            this.workingWith.questionId,
            this.workingWith.answered
          );
          break;
        case "Decision":
          this.deadlineTime.answered = answer;
          this.sendAnswerNextQuestion(
            this.deadlineTime.questionId,
            this.deadlineTime.answered
          );
          this.sendUpdateOneField("contact", {
            estimated_time: this.getDateFoward(answer)
          });
          break;
        default:
          break;
      }
    },
    sendAnswerNextQuestion(idQ, answer) {
      this.nextQuestionSt({
        leadId: this.lead._id,
        questionId: idQ,
        answer: answer
      }).then(va => {
        this.$store.commit("leads/setUpdateLeadByIdField", {
          id: this.lead._id,
          field: "profile_percentage",
          data: {
            profile_percentage: va.profile_percentage
          }
        });
        this.$store.commit("leads/updateActualLead", {
          page: "list",
          profile_percentage: va.profile_percentage
        });
        this.$store.commit("leads/updateActualLead", {
          page: "kanban",
          profile_percentage: va.profile_percentage
        });
        this.innerLoading = false;
      });
    },
    sendUpdateOneField(field, value) {
      this.innerLoading = field;
      setTimeout(() => {
        let updateData = {
          leadId: this.lead._id,
          contactLeadId: this.lead.contact_lead_id,
          lead: {}
        };
        updateData.lead[field] = value;
        this.sendUpdateLead(updateData);
      }, 250);
    },
    sendUpdateLead(updateData) {
      let self = this;
      self
        .fetchUpdatelLead(updateData)
        .catch(() => {
          self.$snotify.error(
            "Error!!!",
            `Ocurrio un problema actualizar el lead.`,
            {
              closeOnClick: true,
              timeout: 4000
            }
          );
        })
        .finally(() => {
          self.setLoading(false);
          setTimeout(() => {
            self.innerLoading = false;
          }, 1000);
        });
    },
    sendUpdateLeadPhase(value) {
      if (value) {
        const phaseLead = value.split("_");
        const valuePhase = phaseLead[0];
        const phase = phaseLead[phaseLead.length - 1];
        if (phase === "tracking") {
          this.sendUpdateOneField("tracking_phase", valuePhase);
        } else {
          this.sendUpdateOneField("operation_phase", valuePhase);
        }
      }
    },
    getDateBefore(option) {
      switch (option) {
        case "1 mes":
          return moment()
            .utc()
            .subtract(1, "months")
            .format();

        case "6 meses":
          return moment()
            .utc()
            .subtract(6, "months")
            .format();
        case "+ de 6 meses":
          return moment()
            .utc()
            .subtract(7, "months")
            .format();

        default:
          return moment().format();
      }
    },
    getDateFoward(option) {
      switch (option) {
        case "Menos de 30 días":
          return moment()
            .utc()
            .add(29, "days")
            .format();

        case "1 a 3 meses":
          return moment()
            .utc()
            .add(3, "months")
            .format();
        case "3 a 6 meses":
          return moment()
            .utc()
            .add(6, "months")
            .format();

        case "+ de 6 meses":
          return moment()
            .utc()
            .add(7, "months")
            .format();

        default:
          return moment().format();
      }
    }
  }
};
</script>
<style>
.caption-font {
  font-size: 12px;
  text-transform: none;
}
.loading-layer {
  min-height: 300px;
}
.v-menu__content.menuable__content__active {
  background-color: #f5f5f5;
  padding: 14px 0;
}
.v-slider__thumb-label.primary {
  background-color: transparent !important;
}
.v-toolbar.lead-phase-active {
  background-color: #424242;
}
.v-toolbar.lead-phase-active.lead-tracking-phase-unassigned {
  background-color: #671717;
}
.v-toolbar.lead-phase-in-operation {
  background-color: #172367;
}
.v-toolbar.lead-phase-finished {
  background-color: #fffbe8;
  color: rgb(104, 100, 100) !important;
}
.v-toolbar.lead-phase-discarded {
  background-color: #757575;
}
.v-text-field {
  padding-top: 0px !important;
}
.custom-placeholer-color input::placeholder {
  color: black !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: black;
  opacity: 1;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
</style>
